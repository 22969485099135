import axios from '@/libs/axios'

export default {
    namespaced: true,
    state: {
        allSales: {},
    },
    getters: {
        _allSales(state) {
            return state.allSales
        },
    },
    mutations: {
        setAllSales(state, content) {
            state.allSales = content
        },
    },
    actions: {
        allSales(context) {
            axios.get('home/statistics/all_sales')
                .then(res => {
                    if (res.data.status == 'success') {
                        context.commit('setAllSales', res.data.content)
                    }
                })
        },
        productStats(context, params) {
            return axios.get('home/statistics/product_stats', {params})
                .then(res => {
                    return res.data.content
                })
        },
        brandStats(context, params) {
            return axios.get('home/statistics/brand_stats', {params})
                .then(res => {
                    return res.data.content
                })
        }
    },
}
